import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layouts'

class Collages extends Component {
  render() {
    const collages = this.props.data.allContentfulImageCollection.edges[0].node.images.map(
      (img) => (
        <div key={img.id} className="f f-col f-center a-center">
          <GatsbyImage
            image={img.gatsbyImageData}
            alt={img.title}
            className="image__img--collage fadeInUp"
          />
        </div>
      ),
    )

    return (
      <Layout>
        <div className="grid-images">{collages}</div>
      </Layout>
    )
  }
}

export default Collages

export const query = graphql`
  query CollectionOfCollagesQuery {
    allContentfulImageCollection(
      filter: { category: { title: { eq: "Collages" } } }
    ) {
      edges {
        node {
          images {
            id
            title
            gatsbyImageData(placeholder: NONE)
          }
        }
      }
    }
  }
`
